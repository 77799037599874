import React, { useEffect } from 'react'
import Nav from '../../components/Nav/Nav';
import Footer from '../../components/footer/Footer';
import TeamMain from './TeamMain';
import Teams from './Teams';
import { Link } from 'react-router-dom';

const Team = () => {

    useEffect(()=>{
        window.location.href = "https://bridgefort.co.za"
    },[])

    return (
        <div>
            <Link to="/">
                <button className='floating-btn' style={{ position: 'fixed', zIndex: 100, right: 0, top: '50%' }} >
                    Invest Now
                </button>
            </Link>
            <Nav />
            <TeamMain />
            <Teams />
            <Footer />
        </div>
    )
}

export default Team;